import React from 'react';

import logo from '../../images/elefante.png';
import DrawerToggleButton from '../DrawerToggleButton';

import './styles.css';

const Header = props => {
  return (
    <header className="toolbar">

      <nav className="toolbar__navigation">
        { window.innerWidth < 1200 && (
          <div className="drawer__toggle__button" >
            <DrawerToggleButton click={props.drawerClickHandler} />
          </div>
        )}
        <div >
          <a className="toolbar__logo" href="/">
            <img src={logo} className="toolbar__logo-far-east" alt="Espaço Far East"/>
            <p>ESPAÇO <span>FAR EAST</span></p>
          </a>
        </div>
        <div className="spacer" />
          { window.innerWidth > 1200 && (
            <div className="toolbar__navigation-items">
              <ul>
                <li><a href="#LinhaSobre">Sobre</a></li>
                <li><a href="#LinhaGaleria">Galeria</a></li>
                <li><a href="#LinhaContato">Contato</a></li>
                <li><a href="#LinhaComo_Chegar">Como Chegar</a></li>
                <li><a target="blank" href="https://webforms.pipedrive.com/f/2XDf61vvOcxJeV7n9VUaqfyQXXnnpkPZ9jDnEwNQy1rtj3pyEiIlgX82j5uDUKQLh" id="orcamento">Orçamento</a></li>
              </ul>
            </div>
          )}
        {/* <div className="mobile__buttons"> */}
          {/* <a target="blank" href="https://wa.me/5531999362129">
            <img className="whatsapp" src={whatsapp} alt="whastapp"/>
          </a> */}
          {/* <a href="https://www.instagram.com/espacofareast/" target="blank">
            <img src={InstaLogo} className="instagram" alt="instagram"/>
          </a> */}
        {/* </div> */}
        <img width="60" alt="Espaço Far East" src="https://asset1.zankyou.com/img/sections/badgets/ziwa-winners/badge-ziwa2021.png" />

      </nav>
    </header>
  );
}

export default Header;
