import React from 'react';

import './Global.css';

import Routes from './routes';

function App() {
  return (
    <div>
      <Routes/>

    </div>
  );
}

export default App;
