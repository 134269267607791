import React from 'react';

import './styles.css'

export default function mapa() {
  return (
    <div className="como__chegar">
      <div className="button__rota">
        <a
          href="https://www.google.com/maps/dir//Far+East+-+Espa%C3%A7o+de+eventos+-+Rua+Kenedy+-+Jardim+Canada,+Nova+Lima+-+MG/@-20.0579598,-43.9810754,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0xa6bb79d7fc8527:0x5e79f6fa47ccfac4!2m2!1d-43.9785645!2d-20.0575373?hl=pt-BR"
          target="blank"
        >
          <button className="calcular__rota__button">
            Google Maps
          </button>
        </a>
        <a
          href="https://www.waze.com/ul?place=ChIJJ4X813m7pgARxPrMR_r2eV4&ll=-20.05769850%2C-43.97815680&navigate=yes"
          target="blank"
        >
          <button className="calcular__rota__button">
            Waze
          </button>
        </a>
      </div>
      <iframe
        title="Endereço Far East"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3747.830824891543!2d-43.97865999999998!3d-20.057527600000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa6bb79d7fc8527%3A0x5e79f6fa47ccfac4!2sFar%20East%20-%20Espa%C3%A7o%20de%20eventos!5e0!3m2!1spt-BR!2sbr!4v1578575569590!5m2!1spt-BR!2sbr"
        id="google-maps"
        frameBorder="0"
      ></iframe>
    </div>
  );
}

