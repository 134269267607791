import React from 'react';
import X from '../../images/x.svg'
import { Button } from '../Button';
import Backdrop from '../Backdrop';
import './styles.css';

export function Notification({
  title,
  onClick,
  buttonTitle,
  closeWindow,
  isMobile,
}) {

return (
    <>
      <Backdrop onClick={closeWindow} />
      <div className={`notification__container ${isMobile ? 'isActive' : ''}`}>
        <button
          onClick={closeWindow}
          className="notification__close__button"
        >
          <img src={X} alt="Espaço Far East" />
        </button>
        <p className="notification__title">{title}</p>
        <Button onClick={onClick} title={buttonTitle} />
      </div>
    </>
  );
}
