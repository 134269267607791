import React from 'react';

import elefante from '../../images/elefante.png';
import elefanteReverso from '../../images/elefantereverso.png';

import './styles.css';

const SideDrawer = () => {
  return (
    <nav className="side-drawer">
      <ul className="side-drawer__list">
        <li><a href="/" className="far-east">Far East</a></li>
        <li><a href="#LinhaSobre">Sobre</a></li>
        <li><a href="#LinhaGaleria">Galeria</a></li>
        <li><a href="#LinhaContato">Contato</a></li>
        <li><a href="#LinhaComo_Chegar">Como Chegar</a></li>
        <li>
          <a
            target="blank" href="https://webforms.pipedrive.com/f/1HI1Q5SyKqRPqKCDCYZdC6aegMY0W1tpe7zsosLxqsQ9e9JTGCGrtRkweObbYbE0b"
            id="orcamento"
          >Orçamento
          </a>
        </li>
      </ul>

      <div className="presentation">
        <div className="title">
          <p>ESPAÇO</p>
          <span>FAR EAST</span>
        </div>
        <div className="slogan">
          <img src={elefante} alt="Far East" className="elefante"/>
          <div className="Slogan-Container">
            <p>DO TAMANHO DOS SEUS</p>
            <h3 className="sonhos">SONHOS</h3>
          </div>
          <img src={elefanteReverso} alt="Far East" className="elefante"/>
        </div>
      </div>
    </nav>
  );
}

export default SideDrawer;
