import React, { useState } from 'react';
import * as emailjs from 'emailjs-com';

import './styles.css';

export default function FormularioContato() {
  const [ nome, setNome ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ telefone, setTelefone ] = useState();
  const [ assunto, setAssunto ] = useState('');
  const [ mensagem, setMensagem ] = useState('');

  function handleSubmit(e){
    e.preventDefault()

    const templateParams = {
      from_email: email,
      to_email: 'atendimento@fareast.com.br',
      nome: nome,
      telefone: telefone,
      assunto: assunto,
      mensagem: mensagem,
    }

    try {
      emailjs.send(
        'gmail',
        'template_formularioContatoSite',
        templateParams,
        'user_q3P7CN4KoqC4BypFtOBMR',
      )
      alert('A sua mensagem foi enviada com sucesso!')
      resetForm()
    } catch (err) {
      alert('Erro no envio da mensagem, tente novamente.', err)
    }
  }

  function resetForm() {
    setNome('')
    setEmail('')
    setTelefone('')
    setAssunto('')
    setMensagem('')
  }


  return (
    <div className="formulario">
      <h3 className="Titulo__Formulario">Deixe aqui a sua mensagem</h3>
      <form onSubmit={handleSubmit}>
          <span className="Titulo__Campo__Nome" >Nome:</span>
          <input
            required
            placeholder="Seu nome"
            value={nome}
            onChange={e => setNome(e.target.value)}
          />

          <span className="Titulo__Campo__Email" >e-mail:</span>
          <input
            required
            placeholder="abc@seumail.com"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          <span className="Titulo__Campo__Telefone" >Telefone:</span>
          <input
            required
            placeholder="Seu telefone"
            value={telefone}
            onChange={e => setTelefone(e.target.value)}
          />

          <span className="Titulo__Campo__Assunto" >Assunto:</span>
          <input
            required
            placeholder="Casamento, 15 anos, corporativo, ..."
            value={assunto}
            onChange={e => setAssunto(e.target.value)}
          />

          <span className="Titulo__Campo__Mensagem" >Mensagem:</span>
          <input
            required
            placeholder="Ex: Quero agendar uma visita"
            type="textarea"
            value={mensagem}
            onChange={e => setMensagem(e.target.value)}
          />

        <button type="submit">
          Envie a sua mensagem
        </button>
      </form>
      {/* <Form onSubmit={() => handleSubmit()}>
        <FormGroup controlId="formularioCampoNome">
          <Label className="Titulo__Campo__Nome" >Nome:</Label>
          <Input
            type="text"
            name="nome"
            value={nome}
            className="text-primary"
            onChange={e => setNome(e.target.value)}
            placeholder="Seu nome"
            required
          />
        </FormGroup>

        <FormGroup controlId="formularioCampoEmail">
          <Label className="Titulo__Campo__Email" >e-mail:</Label>
          <Input
            type="email"
            name="email"
            value={email}
            className="text-primary"
            onChange={e => setEmail(e.target.value)}
            placeholder="abc@seumail.com"
            required
          />
        </FormGroup>

        <FormGroup controlId="formularioCampoTelefone">
          <Label className="Titulo__Campo__Telefone" >Telefone:</Label>
          <Input
            type="integer"
            name="telefone"
            value={telefone}
            className="text-primary"
            onChange={e => setTelefone(e.target.value)}
            placeholder="Seu telefone"
            required
          />
        </FormGroup>

        <FormGroup controlId="formularioCampoAssunto">
          <Label className="Titulo__Campo__Assunto" >Assunto:</Label>
          <Input
            type="text"
            name="assunto"
            value={assunto}
            className="text-primary"
            onChange={e => setAssunto(e.target.value)}
            placeholder="Casamento, 15 anos, corporativo, ..."
            required
          />
        </FormGroup>

        <FormGroup controlId="formularioCampoMensagem">
          <Label className="Titulo__Campo__Mensagem" >Mensagem:</Label>
          <Input
            type="textarea"
            name="mensagem"
            value={mensagem}
            className="text-primary"
            onChange={e => setMensagem(e.target.value)}
            placeholder="Ex: Quero agendar uma visita"
            required
          />
        </FormGroup>

        <Button variant="primary" type="submit">
          Envie a sua mensagem
        </Button>
      </Form> */}
    </div>
  )
}
