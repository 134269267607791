import React, { useState, useEffect, useCallback, useMemo } from 'react';

import Header from '../../Components/Header';
import Mapa from '../../Components/Mapa';
import Contato from '../../Components/Contato';
import SideDrawer from '../../Components/SideDrawer';
import Backdrop from '../../Components/Backdrop';
import { Notification } from '../../Components/Notification';

import imageHeader from '../../images/entradaFarEast.jpg';
import elefante from '../../images/elefante.png';
import elefanteReverso from '../../images/elefantereverso.png';
import entrada from '../../images/entradaOscar.jpg';
import cerimonia from '../../images/cerimonia1.jpg';
import eventoCorporate from '../../images/eventoCorporativo.jpg';
import escadaAmpla from '../../images/escadaAmpla.jpg';
import imageEndSobre from '../../images/imgHeader2.jpg';
import planta from '../../images/planta.png'
import wheelchair from '../../images/wheelchair-icon.png';
import FiX from '../../images/x.svg';
import ArrowLeft from '../../images/keyboard_arrow_left-48dp.svg';
import ArrowRight from '../../images/keyboard_arrow_right-48dp.svg';

import wed_1 from '../../images/casamento/1-Espaco_Far_East-Entrada-Foto_Gustavo_Lovalho.png';
import wed_2 from '../../images/casamento/2-Espaco_Far_East-Espaco_Marrakech-Foto_Gustavo_Lovalho.png';
import wed_3 from '../../images/casamento/3-Espaco_Far_East-Foto_Marilia_Birchal_Robbe-Espaco_Cingapura.png';
import wed_4 from '../../images/casamento/4-Espaco_Far_East-Espaco_Istambul-Foto_Gustavo_Lovalho.png';
import wed_5 from '../../images/casamento/5-Espaco_Far_East-Espaco_Marrakech-Decor_Verde_Que_Te_Quero_Verde.jpg';
import wed_6 from '../../images/casamento/6-Espaco_Far_East-Deck-Foto_Gustavo_Lovalho-6.jpg';
import wed_7 from '../../images/casamento/7-Espaco_Far_East-Espaco_Istambul-Decor_Verde_Que_Te_Quero_Verde.png';
import wed_8 from '../../images/casamento/8-Espaco_Far_East-Decor_Verde_Que_Te_Quero_Verde.jpg';
import wed_9 from '../../images/casamento/9-Espaco_Far_East-Deck-Foto_Gustavo_Lovalho-1.jpg';
import wed_10 from '../../images/casamento/10-Espaco_Far_East-Entrada-Foto_Gustavo_Lovalho-2.png';
import wed_11 from '../../images/casamento/11-Espaco_Far_East-Deck-Foto_Gustavo_Lovalho-3.png';
import wed_12 from '../../images/casamento/12-Espaco_Far_East-Deck-Foto_Gustavo_Lovalho-7.png';
import wed_13 from '../../images/casamento/13-Espaco_Far_East-Deck-Foto_Gustavo_Lovalho.png';
import wed_14 from '../../images/casamento/14-Espaco_Far_East-Espaco_Marrakech-Decor_RD_Design-3.jpg';
import wed_15 from '../../images/casamento/15-Espaco_Far_East-Espaco_Marrakech-Decor_RD-Foto_Fabiano_Aguiar_Fotografia.png';
import wed_16 from '../../images/casamento/16-Espaco_Far_East-Espaco_Cingapura-Foto_Luiza_Ferraz.jpg';
import wed_17 from '../../images/casamento/17-Espaco_Far_East-Deck-1.png';
import wed_18 from '../../images/casamento/18-Espaco_Far_East-Patio_Externo.jpg';
import wed_19 from '../../images/casamento/19-Espaco_Far_East-Deck-Foto_Aproximar_Fotografia-5.png';
import wed_20 from '../../images/casamento/20-Espaco_Far_East-Espaco_Istambul-Foto_Gustavo_Lovalho-1.jpg';

import fifteen_1 from '../../images/15anos/1-Espaco_Far_East-Marrakech-Luciana.jpg';
import fifteen_2 from '../../images/15anos/2-Espaco_Far_East-Deck.jpg';
import fifteen_3 from '../../images/15anos/3-Espaco_Far_East-Deck.jpg';
import fifteen_4 from '../../images/15anos/4-Espaco_Far_East-Marrakech-Helena-2.jpg';
import fifteen_5 from '../../images/15anos/5-Espaco_Far_East-Deck-Laura.jpeg';
import fifteen_6 from '../../images/15anos/6-Espaco_Far_East-Entrada-Helena.jpg';
import fifteen_7 from '../../images/15anos/7-Espaco_Far_East-Deck-Helena-3.jpg';
import fifteen_8 from '../../images/15anos/8-Espaco_Far_East-Marrakech-Foto_Daniela_Pitanguy-Decor_Monica_Lipiani_1.jpg';
import fifteen_9 from '../../images/15anos/9-Espaco_Far_East-Marrakech-Foto_Daniela_Pitanguy-Decor_Monica_Lipiani_2.png';
import fifteen_10 from '../../images/15anos/10-Espaco_Far_East-Marrakech-Foto_Daniela_Pitanguy-Decor_Monica_Lipiani_.jpg';
import fifteen_11 from '../../images/15anos/11-Espaco_Far_East-Istambul-Foto_Daniela_Pitanguy-Decor_Monica_Lipiani_.jpg';
import fifteen_12 from '../../images/15anos/12-Espaco_Far_East-Marrakech-Foto_Daniela_Pitanguy-Decor_Monica_Lipiani.jpg';
import fifteen_13 from '../../images/15anos/13-Espaco_Far_East-Marrakech-Foto_Daniela_Pitanguy-Decor_Monica_Lipiani_3.jpg';
import fifteen_14 from '../../images/15anos/14-Espaco_Far_East-Gabriela-Foto_Adriana_Goncalves_1.jpeg';
import fifteen_15 from '../../images/15anos/15-Espaco_Far_East-Espaco_Marrakech-Gabriela-Foto_Adriana_Goncalves_2.jpeg';
import fifteen_16 from '../../images/15anos/16-Espaco_Far_East-Deck-Gabriela-Foto_Adriana_Goncalves_.jpeg';
import fifteen_17 from '../../images/15anos/17-Espaco_Far_East-Espaco_Marrakech-Vitoria-Foto_Gustavo_Lovalho-2.jpg';
import fifteen_18 from '../../images/15anos/18-Espaco_Far_East-Espaco_Marrakech-Vitoria-Foto_Gustavo_Lovalho-3.jpg';
import fifteen_19 from '../../images/15anos/19-Espaco_Far_East-Espaco_Marrakech-Vitoria-Foto_Gustavo_Lovalho-5.jpg';
import fifteen_20 from '../../images/15anos/20-Espaco_Far_East-Espaco_Istambul-Vitoria-Foto_Gustavo_Lovalho-6.jpg';
import { WhatsappButton } from '../../Components/WhatsappButton';

// ========= Dados das Fotos da Galeria ========= //
const dataWedding = [
  {
    "id": 0,
    "image": `${wed_1}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Gustavo Lovalho-0",
    "decorador": "Decor Foto-0",
  },
  {
    "id": 1,
    "image": `${wed_2}`,
    "url_instagram": "https://www.instagram.com/p/CA1DbvSl8px/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto1",
  },
  {
    "id": 2,
    "image": `${wed_3}`,
    "url_instagram": "https://www.instagram.com/p/CAszlQAlBgm/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto2",
  },
  {
    "id": 3,
    "image": `${wed_4}`,
    "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto3",
  },
  {
    "id": 4,
    "image": `${wed_5}`,
    "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto4",
  },
  {
    "id": 5,
    "image": `${wed_6}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto5",
  },
  {
    "id": 6,
    "image": `${wed_7}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Gustavo Lovalho-0",
    "decorador": "Decor Foto-0",
  },
  {
    "id": 7,
    "image": `${wed_8}`,
    "url_instagram": "https://www.instagram.com/p/CA1DbvSl8px/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto1",
  },
  {
    "id": 8,
    "image": `${wed_9}`,
    "url_instagram": "https://www.instagram.com/p/CAszlQAlBgm/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto2",
  },
  {
    "id": 9,
    "image": `${wed_10}`,
    "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto3",
  },
  {
    "id": 10,
    "image": `${wed_11}`,
    "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto4",
  },
  {
    "id": 11,
    "image": `${wed_12}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto5",
  },
  {
    "id": 12,
    "image": `${wed_13}`,
    "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto3",
  },
  {
    "id": 13,
    "image": `${wed_14}`,
    "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto4",
  },
  {
    "id": 14,
    "image": `${wed_15}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto5",
  },
  {
    "id": 15,
    "image": `${wed_16}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Luíza Ferraz",
    "decorador": "Olguinha Ullmann - RD Design",
  },
  {
    "id": 16,
    "image": `${wed_17}`,
    "url_instagram": "https://www.instagram.com/p/CA1DbvSl8px/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto1",
  },
  {
    "id": 17,
    "image": `${wed_18}`,
    "url_instagram": "https://www.instagram.com/p/CAszlQAlBgm/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto2",
  },
  {
    "id": 18,
    "image": `${wed_19}`,
    "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto3",
  },
  {
    "id": 19,
    "image": `${wed_20}`,
    "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto4",
  },
]
// const dataWedding = [
//   {
//     "id": 0,
//     "image": "https://sitefareast.s3.amazonaws.com/1+Far+East+-+Entrada+-+Foto+Gustavo+Lovalho+.png",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho-0",
//     "decorador": "Decor Foto-0",
//   },
//   {
//     "id": 1,
//     "image": "https://sitefareast.s3.amazonaws.com/2+Far+East++-+Espac%CC%A7o+Marrakech++-+Foto+Gustavo+Lovalho.png",
//     "url_instagram": "https://www.instagram.com/p/CA1DbvSl8px/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto1",
//   },
//   {
//     "id": 2,
//     "image": "https://sitefareast.s3.amazonaws.com/3+Far+East+-+Espac%CC%A7o+Cingapura.png",
//     "url_instagram": "https://www.instagram.com/p/CAszlQAlBgm/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto2",
//   },
//   {
//     "id": 3,
//     "image": "https://sitefareast.s3.amazonaws.com/4+Far+East++-+Espac%CC%A7o+Istambul+-+Foto+Gustavo+Lovalho.png",
//     "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto3",
//   },
//   {
//     "id": 4,
//     "image": "https://sitefareast.s3.amazonaws.com/5+Far+East+Espac%CC%A7o+Marrakech+-+Decor+Verde+que+te+quero+Verde.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto4",
//   },
//   {
//     "id": 5,
//     "image": "https://sitefareast.s3.amazonaws.com/6+Far+East+-+Deck+-+Foto+Gustavo+Lovalho+6.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 6,
//     "image": "https://sitefareast.s3.amazonaws.com/7+Far+East++-+Espac%CC%A7o+Istambul+-+Verde+que+te+quero+Verde.png",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho-0",
//     "decorador": "Decor Foto-0",
//   },
//   {
//     "id": 7,
//     "image": "https://sitefareast.s3.amazonaws.com/8+Far+East++-+Decor+Verde+que+te+quero+Verde.jpg",
//     "url_instagram": "https://www.instagram.com/p/CA1DbvSl8px/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto1",
//   },
//   {
//     "id": 8,
//     "image": "https://sitefareast.s3.amazonaws.com/9+Far+East+-+Deck+-+Foto+Gustavo+Lovalho+1.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAszlQAlBgm/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto2",
//   },
//   {
//     "id": 9,
//     "image": "https://sitefareast.s3.amazonaws.com/10+Far+East+-+Entrada+-+Foto+Gustavo+Lovalho+2.png",
//     "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto3",
//   },
//   {
//     "id": 10,
//     "image": "https://sitefareast.s3.amazonaws.com/11+Far+East+-+Deck+-+Foto+Gustavo+Lovalho+3.png",
//     "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto4",
//   },
//   {
//     "id": 11,
//     "image": "https://sitefareast.s3.amazonaws.com/12+Far+East+-+Deck+-+Foto+Gustavo+Lovalho+7.png",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 12,
//     "image": "https://sitefareast.s3.amazonaws.com/13+Far+East+-+Deck+-+Foto+Gustavo+Lovalho.png",
//     "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto3",
//   },
//   {
//     "id": 13,
//     "image": "https://sitefareast.s3.amazonaws.com/14+Far+East+Espac%CC%A7o+Marrakech+-+Decor+RD+Design+3.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto4",
//   },
//   {
//     "id": 14,
//     "image": "https://sitefareast.s3.amazonaws.com/15+Far+East+-+Espac%CC%A7o+Marrakech+-+Decor+RD+-+Fabiano+Aguiar+Fotografia.png",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 15,
//     "image": "https://sitefareast.s3.amazonaws.com/16+Far+East+-+Espac%CC%A7o+Cingapura+-+Foto+Luiza+Ferraz.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho-0",
//     "decorador": "Decor Foto-0",
//   },
//   {
//     "id": 16,
//     "image": "https://sitefareast.s3.amazonaws.com/17+Far+East+Deck+1.png",
//     "url_instagram": "https://www.instagram.com/p/CA1DbvSl8px/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto1",
//   },
//   {
//     "id": 17,
//     "image": "https://sitefareast.s3.amazonaws.com/18+Far+East+-+Pa%CC%81tio+Externo.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAszlQAlBgm/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto2",
//   },
//   {
//     "id": 18,
//     "image": "https://sitefareast.s3.amazonaws.com/19+Far+East+-+Deck+-+Aproximar+Fotografia+5.png",
//     "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto3",
//   },
//   {
//     "id": 19,
//     "image": "https://sitefareast.s3.amazonaws.com/20+Far+East++-+Espac%CC%A7o+Istambul+-+Foto+Gustavo+Lovalho+1.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto4",
//   },
// ]
// const dataCorporate = [
//   {
//     "id": 0,
//     "image": "https://sitefareast.s3.amazonaws.com/Corporativo/202016Bco+BS.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
//     "fotografo": "Banco Bonsucesso",
//     "decorador": "Decor Foto - 0",
//   },
//   {
//     "id": 1,
//     "image": "https://sitefareast.s3.amazonaws.com/Corporativo/Fotos_Gustavo+Lovalho-GL+Fotografia0001.jpg",
//     "url_instagram": "https://www.instagram.com/p/CA1DbvSl8px/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto1",
//   },
//   {
//     "id": 2,
//     "image": "https://sitefareast.s3.amazonaws.com/Corporativo/Fotos_Gustavo+Lovalho-GL+Fotografia0002.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAszlQAlBgm/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto2",
//   },
//   {
//     "id": 3,
//     "image": "https://sitefareast.s3.amazonaws.com/Corporativo/Fotos_Gustavo+Lovalho-GL+Fotografia0004.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto3",
//   },
//   {
//     "id": 4,
//     "image": "https://sitefareast.s3.amazonaws.com/Corporativo/Fotos_Gustavo+Lovalho-GL+Fotografia0022.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto4",
//   },
//   {
//     "id": 5,
//     "image": "https://sitefareast.s3.amazonaws.com/Corporativo/Fotos_Gustavo+Lovalho-GL+Fotografia0026.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 6,
//     "image": "https://sitefareast.s3.amazonaws.com/Corporativo/Fotos_Gustavo+Lovalho-GL+Fotografia0031.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto4",
//   },
//   {
//     "id": 7,
//     "image": "https://sitefareast.s3.amazonaws.com/Corporativo/Fotos_Gustavo+Lovalho-GL+Fotografia0036.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 8,
//     "image": "https://sitefareast.s3.amazonaws.com/Corporativo/Fotos_Gustavo+Lovalho-GL+Fotografia0037.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 9,
//     "image": "https://sitefareast.s3.amazonaws.com/Corporativo/252447Bco+BSA.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Banco Bonsucesso",
//     "decorador": "Decor Foto5",
//   },

// ]
const dataFifteen = [
  {
    "id": 0,
    "image": `${fifteen_1}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Luciana",
    "decorador": "Decor Foto-0",
  },
  {
    "id": 1,
    "image": `${fifteen_2}`,
    "url_instagram": "https://www.instagram.com/p/CA1DbvSl8px/",
    "fotografo": "Far East",
    "decorador": "Decor Foto1",
  },
  {
    "id": 2,
    "image": `${fifteen_3}`,
    "url_instagram": "https://www.instagram.com/p/CAszlQAlBgm/",
    "fotografo": "Far East",
    "decorador": "Decor Foto2",
  },
  {
    "id": 3,
    "image": `${fifteen_4}`,
    "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
    "fotografo": "Far East",
    "decorador": "Decor Foto3",
  },
  {
    "id": 4,
    "image": `${fifteen_5}`,
    "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
    "fotografo": "Laura",
    "decorador": "Decor Foto4",
  },
  {
    "id": 5,
    "image": `${fifteen_6}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Helena",
    "decorador": "Decor Foto5",
  },
  {
    "id": 6,
    "image": `${fifteen_7}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Helena",
    "decorador": "Decor Foto5",
  },
  {
    "id": 7,
    "image": `${fifteen_8}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Daniela Pitanguy",
    "decorador": "Decor Foto5",
  },
  {
    "id": 8,
    "image": `${fifteen_9}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Daniela Pitanguy",
    "decorador": "Decor Foto5",
  },
  {
    "id": 9,
    "image": `${fifteen_10}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Daniela Pitanguy",
    "decorador": "Decor Foto5",
  },
  {
    "id": 10,
    "image": `${fifteen_11}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Daniela Pitanguy",
    "decorador": "Decor Foto5",
  },
  {
    "id": 11,
    "image": `${fifteen_12}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Mariana",
    "decorador": "Decor Foto5",
  },
  {
    "id": 12,
    "image": `${fifteen_13}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Mariana",
    "decorador": "Decor Foto5",
  },
  {
    "id": 13,
    "image": `${fifteen_14}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Adriana Gonçalves",
    "decorador": "Decor Foto5",
  },
  {
    "id": 14,
    "image": `${fifteen_15}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Adriana Gonçalves",
    "decorador": "Decor Foto5",
  },
  {
    "id": 15,
    "image": `${fifteen_16}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Adriana Gonçalves",
    "decorador": "Decor Foto5",
  },
  {
    "id": 16,
    "image": `${fifteen_17}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto5",
  },
  {
    "id": 17,
    "image": `${fifteen_18}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto5",
  },
  {
    "id": 18,
    "image": `${fifteen_19}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto5",
  },
  {
    "id": 19,
    "image": `${fifteen_20}`,
    "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
    "fotografo": "Gustavo Lovalho",
    "decorador": "Decor Foto5",
  }
]
// const dataFifteen = [
//   {
//     "id": 0,
//     "image": "https://sitefareast.s3.amazonaws.com/1+Far+East+Espac%CC%A7o+Marrakech+-+Luciana.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Luciana",
//     "decorador": "Decor Foto-0",
//   },
//   {
//     "id": 1,
//     "image": "https://sitefareast.s3.amazonaws.com/2+Far+East+Deck.jpg",
//     "url_instagram": "https://www.instagram.com/p/CA1DbvSl8px/",
//     "fotografo": "Far East",
//     "decorador": "Decor Foto1",
//   },
//   {
//     "id": 2,
//     "image": "https://sitefareast.s3.amazonaws.com/3+Espac%CC%A7o+Far+East+Deck.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAszlQAlBgm/",
//     "fotografo": "Far East",
//     "decorador": "Decor Foto2",
//   },
//   {
//     "id": 3,
//     "image": "https://sitefareast.s3.amazonaws.com/4+Far+East+++Espac%CC%A7o+Marrakech+-+Helena+2.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAq2pH1F0UZ/",
//     "fotografo": "Far East",
//     "decorador": "Decor Foto3",
//   },
//   {
//     "id": 4,
//     "image": "https://sitefareast.s3.amazonaws.com/5+Far+East+-+Deck+-+Laura.jpeg",
//     "url_instagram": "https://www.instagram.com/p/CAg1Ubvlc2P/",
//     "fotografo": "Laura",
//     "decorador": "Decor Foto4",
//   },
//   {
//     "id": 5,
//     "image": "https://sitefareast.s3.amazonaws.com/6+Far+East+-+Entrada+-+Helena.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Helena",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 6,
//     "image": "https://sitefareast.s3.amazonaws.com/7+Far+East+-+Deck+-+Helena+3.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Helena",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 7,
//     "image": "https://sitefareast.s3.amazonaws.com/8+Far+East+-+Espac%CC%A7o+Marrakech+-+Daniela+Pitanguy+-+Decor+Mo%CC%82nica+Lipiani+1.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Daniela Pitanguy",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 8,
//     "image": "https://sitefareast.s3.amazonaws.com/9+Far+East+-+Espac%CC%A7o+Marrakech+-+Daniela+Pitanguy+-+Decor+Mo%CC%82nica+Lipiani+2.png",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Daniela Pitanguy",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 9,
//     "image": "https://sitefareast.s3.amazonaws.com/10+Far+East+-+Deck+-+Daniela+Pitanguy+-+Decor+Mo%CC%82nica+Lipiani+7.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Daniela Pitanguy",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 10,
//     "image": "https://sitefareast.s3.amazonaws.com/11+Far+East+-+Espac%CC%A7o+Istambul+-+Daniela+Pitanguy+-+Decor+Mo%CC%82nica+Lipiani+5.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Daniela Pitanguy",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 11,
//     "image": "https://sitefareast.s3.amazonaws.com/12+Far+East+-+Espac%CC%A7o+Marrakech+-+Mariana+-+Decor+Mo%CC%82nica+Lipiani+1.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Mariana",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 12,
//     "image": "https://sitefareast.s3.amazonaws.com/13+Far+East+-+Espac%CC%A7o+Marrakech+-+Mariana+-+Decor+Mo%CC%82nica+Lipiani+3.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Mariana",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 13,
//     "image": "https://sitefareast.s3.amazonaws.com/14+Far+East+-+Espac%CC%A7o+Marrakech+-+Gabriela+-+Foto+Adriana+Gonc%CC%A7alves+1.jpeg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Adriana Gonçalves",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 14,
//     "image": "https://sitefareast.s3.amazonaws.com/15+Far+East+-+Espac%CC%A7o+Marrakech+-+Gabriela+-+Foto+Adriana+Gonc%CC%A7alves+2.jpeg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Adriana Gonçalves",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 15,
//     "image": "https://sitefareast.s3.amazonaws.com/16+Far+East+Deck+-+Gabriela+-+Foto+Adriana+Gonc%CC%A7alves.jpeg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Adriana Gonçalves",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 16,
//     "image": "https://sitefareast.s3.amazonaws.com/17+Far+East+-+Espac%CC%A7o+Marrakech+-+Vito%CC%81ria+-+Foto+Gustavo+Lovalho+2.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 17,
//     "image": "https://sitefareast.s3.amazonaws.com/18+Far+East+-+Espac%CC%A7o+Marrakech+-+Vito%CC%81ria+-+Foto+Gustavo+Lovalho+3.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 18,
//     "image": "https://sitefareast.s3.amazonaws.com/19+Far+East+-+Espac%CC%A7o+Marrakech+-+Vito%CC%81ria+-+Foto+Gustavo+Lovalho+5.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto5",
//   },
//   {
//     "id": 19,
//     "image": "https://sitefareast.s3.amazonaws.com/20+Far+East+-+Espac%CC%A7o+Istambul+-+Vito%CC%81ria+-+Foto+Gustavo+Lovalho+6.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho",
//     "decorador": "Decor Foto5",
//   }
// ]
// const dataSocialEvent = [
//   {
//     "id": 0,
//     "image": "https://sitefareast.s3.amazonaws.com/cerimonia3.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAszlQAlBgm/",
//     "fotografo": "Gustavo Lovalho-0",
//     "decorador": "Decor Foto-0",
//   }
// ]
// const dataProm = [
//   {
//     "id": 0,
//     "image": "https://sitefareast.s3.amazonaws.com/entradaFarEast.jpg",
//     "url_instagram": "https://www.instagram.com/p/CAd1Cdplkra/",
//     "fotografo": "Gustavo Lovalho-0",
//     "decorador": "Decor Foto-0",
//   }
// ]

export default function Home() {
  // ============= Elementos do Menu de Gaveta ============= //
  const [ sideDrawerOpen, setSideDrawerOpen ] = useState(false);
  const [ sideDrawer, setSideDrawer ] = useState();
  const [ backdrop, setBackdrop ] = useState(false);
  const [ phoneGalleryNotification, setPhoneGalleryNotification ] = useState(false);

  // ================== Estados da Galeria ================== //
  const [galeryOpen, setGaleryOpen] = useState(false);
  // ========= Casamento ========= //
  const [ photo_array, setPhoto_array ] = useState([]);
  const [ photo, setPhoto ] = useState({});

  // ================== Funções da Gaveta ================== //
  function drawerToggleClickHandler() {
    sideDrawerOpen ? setSideDrawerOpen(false) : setSideDrawerOpen(true)
  }
  function backdropClickHandler() {
    setSideDrawerOpen(false)
  }

  const isLandscape = useMemo(() => {
    if (window.matchMedia("(orientation: landscape)").matches){
      return true;
    }
    if (window.matchMedia("(orientation: portrait)").matches){
      return false;
    }
    return false;
  }, []);

  const isMobile = useMemo(() => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      return true;
    }
    return false;
  }, []);

  // ========= Notificação para virar o telefone quando na Galeria ========= //
  function handleTurnPhoneNotification() {
    setPhoneGalleryNotification(!phoneGalleryNotification);
  }
  // ========= Galeria ========= //
  function handleGalery(new_photo_array) {
    isMobile && handleTurnPhoneNotification();
    setGaleryOpen(true);
    setPhoto_array(new_photo_array);
    setPhoto(new_photo_array[0]);
    return;
  }

  const handlePhoto = useCallback((e) => {
    if(e === 'PREV') {
      if (photo.id === 0) {
        return setPhoto(photo_array[photo_array.length - 1]);
      } else {
        return setPhoto(photo_array[Number(photo.id) - 1]);
      }
    }

    if(e === 'NEXT') {
      if (photo.id === photo_array.length - 1) {
        return setPhoto(photo_array[0]);
      } else {
        return setPhoto(photo_array[Number(photo.id) + 1]);
      };
    }
  }, [photo_array, photo]);

  // ========= UseEffect da Gaveta ========= //
  useEffect(() => {
    if (!!sideDrawerOpen) {
      setSideDrawer(<SideDrawer/>)
      setBackdrop(<Backdrop click={backdropClickHandler}/>)
    } else {
      setSideDrawer()
      setBackdrop(false)
    }
  }, [sideDrawerOpen])

  return (
    <div className="App">
      <Header drawerClickHandler={drawerToggleClickHandler}/>
      {phoneGalleryNotification && (
        <Notification
          closeWindow={handleTurnPhoneNotification}
          buttonTitle="OK"
          isMobile={isMobile}
          onClick={handleTurnPhoneNotification}
          title="Vire o telefone para ter uma melhor experiência"
        />
      )}
      {sideDrawer}
      {backdrop}
      <main className="main">

        {/* ======================== Home ======================== */}
        <div id="Home">
          <div>
            <img src={imageHeader} alt="Espaço Far East - Entrada" className="imagem__inicial"/>
          </div>
          <div className="presentation">
            <div className="title">
              <p>ESPAÇO</p>
              <span>FAR EAST</span>
            </div>

            <div className="slogan">
              <img src={elefante} alt="Espaço Far East - Elefante" className="elefante"/>
              <div className="Slogan-Container">
                <p>DO TAMANHO DOS SEUS</p>
                <h3 className="sonhos">SONHOS</h3>
              </div>
              <img src={elefanteReverso} alt="Espaço Far East - Elefante" className="elefante"/>
            </div>
          </div>
        </div>
        <div id="LinhaSobre" className="linha"/>

        {/* ======================== Sobre ======================== */}
        <h2 id="Section__Title">SOBRE NÓS</h2>
        <>
          <div id="Sobre__img__text">
            <img src={entrada} alt="Espaço Far East - Entrada" className="sobre__img__left"/>
            <div className="sobre__text__right">
              <div>
                <h3>DO TAMANHO DOS SEUS SONHOS</h3>
                <p>Um espaço nada comum, com um pé direito de até 12 metros de altura, uma arquitetura grandiosa repleta de jardins internos e externos, o Far East foi idealizado e funcionou como uma elegante loja de móveis e adornos asiáticos exclusivos, que atraiu a atenção de clientes de várias partes do país. </p>
              </div>
            </div>
          </div>
          <div className="linhaSobre"/>
          <div id="Sobre__text__img">
            <div className="sobre__text__left">
              <div>
                <h3>NOSSA ÁREA EXTERNA</h3>
                <p>
                  Contornado por cercas vivas, enormes palmeiras e muito verde, nosso gramado é o espaço perfeito para sua cerimonia ou lounge ao ar livre.
                </p>
              </div>
            </div>
            <img src={cerimonia} alt="Espaço Far East - Cerimonia de Casamento" className="sobre__img__right"/>
          </div>
          <div className="linhaSobre"/>
          <div id="Sobre__img__text">
            <img src={escadaAmpla} alt="Espaço Far East - Escada Ampla" className="sobre__img__left"/>
            <div className="sobre__text__right">
              <div>
                <h3>NOSSA ÁREA INTERNA</h3>
                {/* <p>
                  o difere de todos os outros espaços. Os ambientes apesar de separados, são abertos, comunicam entre si e permitem que os convidados usufruam do evento de maneira confortável e agradável.
                </p> */}
                <p>
                  A área interna também possui lindos jardins distribuídos harmoniosamente.
                </p>
                <p>
                  O lanternim que circunda todo o telhado, em conjunto com as portas e janelas amplas, que revelam espaços abertos e verdes, proporcionam uma ventilação natural e constante.
                </p>
              </div>

            </div>
          </div>
          <div className="linhaSobre"/>
          <div id="Sobre__text__img">
            <div className="sobre__text__left">
              <div>
                <h3>EVENTOS DE 50 A 3.000 PESSOAS</h3>
                {/* <p>
                  Por ser um espaço modular, o Far East pode atender de pequenos eventos empresariais e comemorações a grandes eventos e convenções.
                </p> */}
                <p>
                  Por ser um espaço modular, o Far East pode atender confortavelmente pequenos eventos, como o Mini Wedding, em voga atualmente; assim como grandes eventos e convenções.
                </p>
                <p>
                  Seja qual for o seu sonho, ele se encaixa perfeitamente no nosso Espaço!
                </p>
              </div>

            </div>
            <img src={eventoCorporate} alt="Espaço Far East - Corporativo" className="sobre__img__right"/>
          </div>

          <div className="linhaSobre"/>

          <div id="Sobre__planta">
            <img src={planta} alt="Espaço Far East - Planta Baixa" className="planta__img"/>
            <div className="sobre__planta">
              <div>
                <h3>O ESPAÇO FAR EAST</h3>
                <p>
                  Com diversos ambientes que comunicam entre si, o Espaço Far East é divido em:
                </p>
                <ul>
                  <li><p> Piso principal com 920m², que inclui um deck de madeira por onde são acessados os toaletes masculinos e femininos com os seus tetos forrados pelas lindas jades verdes.</p></li>
                  <li><p> Piso inferior com 720m², que inclui um charmoso pátio externo.</p></li>
                  <li><p> Um gramado de 855m², ideal para cerimônias, jantares ou comemorações ao ar live.</p></li>
                  <li><p> Uma cozinha de 120m² com acesso separado para facilitar a entrada de fornecedores por meio de uma doca.</p></li>
                  <li><p> Estacionamento exclusivo de 1.344m².</p></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="acessibilidade">
            <img style={{ width: 40 }} src={wheelchair} alt="Espaço Far East - cadeirante"/>
            <p>Conectado por rampas e plataforma de acessibilidade.</p>
          </div>

          <div className="linhaSobre"/>

          <div>
            <img alt="Espaço Far East - Boate" src={imageEndSobre} className="imgEndSobre"/>
          </div>
        </>

        <div id="LinhaGaleria" className="linha"/>

        {/* ======================== Galeria ======================== */}
        <h2 id="Section__Title">GALERIA</h2>
        {/* <div className="galery__wrapper"> */}

        <div className={`carousel__command`}>
          {galeryOpen && (
            <div className="slide__command">
              <Backdrop onClick={() => setGaleryOpen(false)} />
              <div id="carousel">
                <div id="items-wrapper">
                  <div id="items">
                    <div className={`item ${isLandscape ? 'isLandscape' : ''}`}><img src={photo.image} alt={photo.fotografo} /></div>
                  </div>
                </div>
              </div>
              <div className="control__button__container">
                <button className="close__button" type="button" onClick={() => setGaleryOpen(false)}>
                  <img  alt="Espaço Far East - X" src={FiX}/>
                  FECHAR
                </button>
                {/* <div className="mobile__buttons">
                  <a target="blank" href="https://wa.me/5531999362129">
                    <img className="whatsapp" src={whatsapp} alt="Espaço Far East - whastapp" />
                  </a>
                  <a href="https://www.instagram.com/espacofareast/" target="blank">
                    <img alt="Espaço Far East - Instagram" src={InstaLogo} className="instagram" />
                  </a>
                </div> */}
                <button className="preview__button" onClick={() => handlePhoto('PREV')}>
                  <img alt="Espaço Far East - Arrow Left" src={ArrowLeft}/>
                </button>
                <button className="forward__button" onClick={() => handlePhoto('NEXT')}>
                  <img alt="Espaço Far East - Arrow Right" src={ArrowRight}/>
                </button>
              </div>
            </div>
          )}

          <div className="gallery__button__container">
            <div className="carousel__options__group">
              <button onClick={() => handleGalery(dataWedding)}>
                <h2 className="photo__card__title">CASAMENTOS</h2>
                <img className="carousel__options" src={dataWedding[15].image} alt="Espaço Far East - data" />
              </button>
              <button onClick={() => handleGalery(dataFifteen)}>
                <div className="photo__card__title">
                  <h2>FESTAS DE</h2>
                  <h2>15 ANOS</h2>
                </div>
                <img className="carousel__options" src={dataFifteen[11].image} alt="Espaço Far East - 15 anos" />
              </button>
              {/* <button onClick={() => handleGalery(dataCorporate)}>
                <div className="photo__card__title">
                  <h2>EVENTOS</h2>
                  <h2>CORPORATIVOS</h2>
                </div>
                <img className="carousel__options" src={eventosCorporativos} alt="Espaço Far East - evento corporativo"/>
              </button> */}
            </div>
            {/* <div className="carousel__options__group">
              <button onClick={() => handleGalery(dataProm)}>
                <div className="photo__card__title">
                  <h2>FESTAS</h2>
                  <h2>INFANTIS</h2>
                </div>
                <img className="carousel__options" src={festaInfantil} alt="Espaço Far East - formatura"/>
              </button>
              <button onClick={() => handleGalery(dataSocialEvent)}>
                <div className="photo__card__title">
                  <h2>EVENTOS</h2>
                  <h2>SOCIAIS</h2>
                </div>
                <img className="carousel__options" src={eventosSociais} alt="Espaço Far East - cerimonia"/>
              </button>
              <button onClick={() => handleGalery(dataFareast)}>
                <div className="photo__card__title">
                  <h2>FOTOS DO</h2>
                  <h2>ESPAÇO</h2>
                </div>
                <img className="carousel__options" src={jadeBanheiro} alt="Espaço Far East"/>
              </button>
            </div> */}
          </div>
        </div>

        {/* </div> */}
        {/* ======================== Contato ======================== */}
        <div id="LinhaContato" className="linha" />
          <h2 id="Section__Title">CONTATO</h2>
          <div className="contato-field">
            <Contato />
          </div>

        {/* ======================== Como Chegar ======================== */}
        <div id="LinhaComo_Chegar" className="linha" />
        <>
          <h2 id="Section__Title">COMO CHEGAR</h2>

          <Mapa />
        </>
      </main>

        {/* ======================== Whatsapp Button ======================== */}
      <WhatsappButton />

        {/* ======================== Footer ======================== */}
      <footer id="Footer">
        <img src={elefante} alt="Espaço Far East" className="elefante" /> <h3>&copy; Espaço Far East 2020 </h3><img src={elefanteReverso} alt="Far East" className="elefante"/>
      </footer>
    </div>
  );
}
