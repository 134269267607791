import React from 'react';

import FormularioContato from '../FormularioContato'

import './styles.css';

export default function Contato() {
	return (
		<div className="body__Contato">
			<div className="side__Contatos">
				<div className="contatos">
					<a href="tel:+5531999362129">
						<p>(31) 9 9936 2129</p>
					</a>
					<a href="mailto:atendimento@fareast.com.br">
						<p>atendimento@fareast.com.br</p>
					</a>
				</div>
				<div className="horario__Funcionamento">
					<h4>Horário de Funcionamento</h4>
					<div className="dia__horario">
						<p className="dia">Segunda: </p>
						<p className="hora">9:00 - 17:00</p>
						<p className="dia">Terça: </p>
						<p className="hora">9:00 - 17:00</p>
						<p className="dia">Quarta: </p>
						<p className="hora">9:00 - 17:00</p>
						<p className="dia">Quinta: </p>
						<p className="hora">9:00 - 17:00</p>
						<p className="dia">Sexta: </p>
						<p className="hora">9:00 - 17:00</p>
						<p className="dia">Sábado: </p>
						<p className="hora">10:00 - 14:00</p>
						<p className="dia">Domingo: </p>
						<p className="hora">Fechado</p>
					</div>
				</div>
				<div className="cta__Contatos">
					<a target="blank" href="https://wa.me/5531999362129">
						<button>
							Whatsapp
						</button>
					</a>
					<a target="blank" href="https://webforms.pipedrive.com/f/1HI1Q5SyKqRPqKCDCYZdC6aegMY0W1tpe7zsosLxqsQ9e9JTGCGrtRkweObbYbE0b" id="orcamento">
						<button>
							Orçamento
						</button>
					</a>
				</div>

			</div>
			<FormularioContato className="Formulario__Contato"/>
      {/* Linha abaixo é o Formulário do Pipedrive */}
      {/* <div className="Formulario__Contato" class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1uLODIBZ6H0IRf8STjmrojIw5DvxsPimzsW8plRqkr6otfvIddzKaZUomLebeUHoT"><script src="https://webforms.pipedrive.com/f/loader"></script></div> */}
		</div>
	);
}
