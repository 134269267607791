import React from 'react';
import whatsapp from '../../images/whatsapp.svg';

import './styles.css';

export function WhatsappButton() {
  return (
    <a className="whatsapp" target="blank" href="https://wa.me/5531999362129">
      <img className="whatsapp" src={whatsapp} alt="whastapp"/>
    </a>
  );
}
